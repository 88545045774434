import { ExoticComponent, FC } from "react";
import { Box, BoxProps, chakra, ChakraComponent, Flex, FlexProps, HTMLChakraProps } from "@chakra-ui/react";
import { HTMLMotionProps, motion } from "motion/react";

export const Main = chakra("main");
export const Nav = chakra("nav");
export const Article = chakra("article");
export const Aside = chakra("aside");
export const Footer = chakra("footer");
export const Header = chakra("header");
export type SectionProps = HTMLChakraProps<"section">;
export const Section = chakra("section");
export const Strong = chakra("strong");
export const Span = chakra("span");
export const Option = chakra("option");
export type PictureProps = HTMLChakraProps<"picture">;
export const Picture = chakra("picture");
export const SpanFlex = (props: FlexProps) => <Flex as="span" {...props} />;
export const SpanBox = (props: BoxProps) => <Box as="span" {...props} />;
export const FullSizeFlex = chakra(Flex, {
    baseStyle: {
        pos: "absolute",
        top: 0,
        left: 0,
        boxSize: "100%",
    },
});
export const FullSizeFlexCentered = chakra(Flex, {
    baseStyle: {
        pos: "absolute",
        top: 0,
        left: 0,
        boxSize: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
});

export const DescriptionList = chakra("dl");
export const DescriptionTerm = chakra("dt");
export const DescriptionDefinition = chakra("dd");
export const Figure = chakra("figure");
export const FigCaption = chakra("figcaption");

// Motion + Chakra
type Merge<P, T> = Omit<P, keyof T> & T;
type ChakraComponentExotic<T extends string> = ChakraComponent<FC<T>> & ExoticComponent;
export type MotionBoxProps = Merge<HTMLChakraProps<"div">, HTMLMotionProps<"div">>;
export const MotionBox = motion.create(chakra.div as ChakraComponentExotic<"div">) as FC<MotionBoxProps>;
export type MotionLinkProps = Merge<HTMLChakraProps<"a">, HTMLMotionProps<"a">>;
export const MotionLink = motion.create(chakra.a as ChakraComponentExotic<"a">) as FC<MotionLinkProps>;
