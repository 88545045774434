import { memo, MouseEventHandler, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { Box, Flex, IconButton, IconButtonProps, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { SkipNavLink } from "@chakra-ui/skip-nav";
import { AnimatePresence } from "motion/react";
import { useTranslation } from "next-i18next";
import { EnumNavigationTrack } from "src/analytics/enum";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { MotionBox } from "src/components/base";
import { Button } from "src/components/base/button";
import { HashLinkUnstyled } from "src/components/base/hash-link";
import { WfGlobalStyle } from "src/components/base/wf-global-style";
import { IWfLinkProps, WfLink } from "src/components/base/wf-link";
import { IconArrowRight, IconSearch, IconWikifolioLogo, IconWikifolioShortLogo } from "src/components/icons";
import { NewsTicker } from "src/components/layout/news-ticker/news-ticker";
import { ID_NAV_MOBILE } from "src/consts/dom";
import { getGlobals } from "src/consts/globals";
import { LANGUAGE } from "src/consts/locale";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useUserStore } from "src/stores/user-store";
import { IUser } from "src/types/common";
import { INav } from "src/types/layout";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { GlobalNavSearch } from "../global-nav-search/global-nav-search";
import { Hamburger } from "./hamburger";
import { MenuDesktop } from "./menu-desktop";
import { MenuMobile } from "./menu-mobile";
import { MenuUser } from "./menu-user";

const HIDE_MOBILE = ["none", "none", "flex"];
const SHOW_MOBILE = ["flex", "flex", "none"];

const SHORT_LOGO_LINK_PROPS: Omit<IWfLinkProps, "href"> = { mr: 2, px: 1, w: "auto" };

interface IWikifolioLogoProps {
    logoUrl: string;
    onClick: () => void;
    onAuxClick?: MouseEventHandler<HTMLAnchorElement>;
}

const WikifolioLogo = ({ logoUrl, onClick, onAuxClick }: IWikifolioLogoProps) => {
    const { isLoggedIn } = useUserStore();
    return (
        <WfLink
            href={logoUrl}
            className="gtm-logo"
            aria-label="wikifolio Financial Technologies"
            title="wikifolio Financial Technologies"
            w="133px"
            display="block"
            mr={4}
            flexShrink={0}
            onClick={onClick}
            onAuxClick={onAuxClick}
            px={0}
            {...(isLoggedIn ? SHORT_LOGO_LINK_PROPS : {})}
        >
            {isLoggedIn ? <IconWikifolioShortLogo /> : <IconWikifolioLogo boxSize="100%" overflow="hidden" color="green.600" verticalAlign="top" />}
        </WfLink>
    );
};

const SearchIconBtn = (props: IconButtonProps) => (
    <IconButton variant="ghost" colorScheme="gray" mr={[1, 2]} boxSize={[6, 6, 5]} minW={[6, 6, 5]} icon={<IconSearch boxSize={3} />} {...props} />
);

interface IProps {
    nav: INav;
    user: IUser | null;
    height: number[];
}

const Nav = ({ nav, user, height }: IProps) => {
    const { isOpen: isMobileMenuOpen, onToggle: onMobileMenuToggle, onClose: onMobileMenuClose } = useDisclosure();
    const { isOpen: isSearchOpen, onToggle: onSearchToggle, onClose: onSearchClose } = useDisclosure();
    const { buildLoginModalLink, buildRegisterModalLink } = useLinkBuilder();
    const genericActivity = useGenericActivityFromElysiumMutation();
    const { t } = useTranslation("common");
    const { language } = useRouterLocale();
    const navSearchRef = useRef<HTMLDivElement>(null);
    const { isNewsTickerHidden } = getGlobals();

    const isTickerEnabled = language === LANGUAGE.DE && !isNewsTickerHidden;

    const onHamburgerClick = () => {
        onMobileMenuToggle();

        if (!isMobileMenuOpen) {
            genericActivity({
                activityTypeId: EnumActivityType.NavigationClicked,
                parameter: JSON.stringify({ type: EnumNavigationTrack.Burger }),
                referrer: window.location.href,
            });
        }
    };

    const onWikifolioLogoClick = (url: string) => {
        genericActivity({
            activityTypeId: EnumActivityType.NavigationClicked,
            parameter: JSON.stringify({ type: EnumNavigationTrack.Logo, targetUrl: `${window.location.origin}${url}` }),
            referrer: window.location.href,
        });
    };

    const onSearchIconBtnClick = () => {
        onSearchToggle();

        if (!isSearchOpen) {
            genericActivity({
                activityTypeId: EnumActivityType.NavigationClicked,
                parameter: JSON.stringify({ type: EnumNavigationTrack.Search }),
                referrer: window.location.href,
            });
        }
    };

    useOutsideClick({
        ref: navSearchRef,
        handler: onSearchClose,
    });

    return (
        <>
            {isTickerEnabled && <NewsTicker />}
            {isMobileMenuOpen && (
                <WfGlobalStyle
                    sx={{
                        body: {
                            overflow: ["hidden", "auto"],
                        },
                    }}
                />
            )}
            <SkipNavLink>
                {t("skip-to-content")} <IconArrowRight />
            </SkipNavLink>
            <MotionBox
                as="header"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="fixed"
                top={isTickerEnabled ? 4 : 0}
                h={height}
                sx={{ transition: "height 0.2s" }}
                w="100%"
                bg="white"
                px={[3, 5, 5, 4]}
                pr={[0.5, 3, null]}
                boxShadow="xs"
                initial="closed"
                animate={isMobileMenuOpen ? "open" : "closed"}
                zIndex="sticky"
            >
                <WikifolioLogo
                    logoUrl={nav.logoUrl}
                    onClick={() => onWikifolioLogoClick(nav.logoUrl)}
                    onAuxClick={triggerCallbackOnMiddleClickEvent(() => onWikifolioLogoClick(nav.logoUrl))}
                />
                <MenuDesktop display={HIDE_MOBILE} nav={nav} isSearchOpen={isSearchOpen} />
                <Box flexGrow={1} />
                <SearchIconBtn
                    className="gtm-header-action__search"
                    aria-label={isSearchOpen ? t("close-search") : t("open-search")}
                    onClick={onSearchIconBtnClick}
                />
                <Hamburger
                    className="gtm-header-action__burger"
                    display={SHOW_MOBILE}
                    aria-controls={ID_NAV_MOBILE}
                    aria-expanded={isMobileMenuOpen}
                    aria-label={isMobileMenuOpen ? t("close-nav") : t("open-nav")}
                    onClick={onHamburgerClick}
                />
                <Flex display={HIDE_MOBILE}>
                    {user ? (
                        <MenuUser user={user} links={nav.user!} h={height} />
                    ) : (
                        <>
                            <Button className="gtm-nav-menu__login" as={HashLinkUnstyled} href={buildLoginModalLink()} size="sm" variant="ghost">
                                {t("login-button")}
                            </Button>
                            <Button as={HashLinkUnstyled} href={buildRegisterModalLink()} size="sm" colorScheme="green" ml={2}>
                                {t("register")}
                            </Button>
                        </>
                    )}
                </Flex>
                <AnimatePresence>
                    {isMobileMenuOpen && (
                        <MenuMobile
                            key="mobile-menu"
                            display={SHOW_MOBILE}
                            top={isTickerEnabled ? 11 : height}
                            nav={nav}
                            user={user}
                            onClose={onMobileMenuClose}
                        />
                    )}
                    {isSearchOpen && (
                        <GlobalNavSearch
                            key="global-nav-search"
                            ref={navSearchRef}
                            navHeights={height}
                            onSearchClose={onSearchClose}
                            top={isTickerEnabled ? [0, 0, 4] : 0}
                        />
                    )}
                </AnimatePresence>
            </MotionBox>
        </>
    );
};

export default memo(Nav);
