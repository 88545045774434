import { ReactNode, useRef } from "react";
import {
    Badge,
    Box,
    LinkProps,
    Flex,
    List,
    ListItem,
    SimpleGrid,
    Text,
    VStack,
    // eslint-disable-next-line no-restricted-imports
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import { Variants } from "motion/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { MotionBox, MotionBoxProps, MotionLink, Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { HashLinkUnstyled } from "src/components/base/hash-link";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { IconCogWheel } from "src/components/icons";
import { ID_NAV_MOBILE } from "src/consts/dom";
import { LOGOUT_URL } from "src/consts/urls";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { IUser } from "src/types/common";
import { INav, INavLink } from "src/types/layout";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { useNavigationTracking } from "./use-navigation-tracking";

const itemVariants: Variants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            bounce: 0,
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            bounce: 0,
        },
    },
};

const menuOverlayVariants: Variants = {
    closed: {
        opacity: 0,
        transition: { delay: 0.35, staggerChildren: 0.02, staggerDirection: -1 },
        transitionEnd: {
            pointerEvents: "none",
        },
    },
    open: {
        opacity: 1,
        transition: { staggerChildren: 0.05, delayChildren: 0.2 },
        transitionEnd: {
            pointerEvents: "auto",
        },
    },
};

interface INavLinkProps extends LinkProps {
    href: string;
    isActive: boolean;
    children: ReactNode | ReactNode[];
    target?: string;
    isChildLink?: boolean;
    shouldHaveBorder?: boolean;
}
// Dev-Note: should be wrapped in a NextLink when we want to use Next.js agnostic links
const NavLink = ({ isActive, href, isChildLink = false, shouldHaveBorder = true, children, ...props }: INavLinkProps) => {
    const linkProps = {
        className: "gtm-nav-menu__link",
        href,
        flexShrink: 0,
        display: "flex",
        alignItems: "center",
        mx: isChildLink ? 0 : [2, 4],
        px: isChildLink ? [1, 2] : 0,
        fontWeight: isChildLink ? "normal" : "semibold",
        color: isActive ? "green.500" : undefined,
        _hover: { color: "green.500" },
        _active: { color: "green.500" },
        borderBottomColor: isChildLink || !shouldHaveBorder ? "" : "gray.100",
        borderBottomWidth: isChildLink || !shouldHaveBorder ? 0 : "1px",
    };
    return (
        <HashLinkUnstyled {...linkProps} {...props}>
            {children}
        </HashLinkUnstyled>
    );
};
interface IChildNavItemsProps {
    items: Required<INavLink>["children"];
    currentPath: string;
    onClick: () => void;
}
const ChildNavItems = ({ items, currentPath, onClick: onNavItemClick }: IChildNavItemsProps) => {
    const { t } = useTranslation("common");
    const { trackSubNavClick } = useNavigationTracking();

    const onNavLinkClick = (linkUrl: string) => {
        trackSubNavClick(linkUrl);
        onNavItemClick();
    };

    return (
        <List role="menu">
            <VStack as={ListItem} alignItems="stretch" role="menuitem">
                {items.map(({ link, isNew }, index) => (
                    <NavLink
                        key={`${index}${link}`}
                        href={link.url}
                        target={link.target}
                        isActive={link.url === currentPath}
                        h={[7, 8]}
                        pl={[2, 3]}
                        fontWeight="normal"
                        onClick={() => {
                            onNavLinkClick(link.url);
                        }}
                        onAuxClick={triggerCallbackOnMiddleClickEvent(() => onNavLinkClick(link.url))}
                        isChildLink
                    >
                        <Span fontSize={["md", "xl"]} noOfLines={1} wordBreak="break-all">
                            {link.name}
                        </Span>
                        {isNew && (
                            <Badge colorScheme="green" size="sm" ml={1}>
                                {t("new")}
                            </Badge>
                        )}
                    </NavLink>
                ))}
            </VStack>
        </List>
    );
};

interface IUserAreaProps {
    user: IUser;
    userSettingUrl: string;
    onClose: () => void;
}
// Dev-Note: should be wrapped in a NextLink when we want to use Next.js agnostic links
const UserArea = ({ user, userSettingUrl, onClose }: IUserAreaProps) => {
    const getUserName = useGetUserName();
    return (
        <MotionLink href={userSettingUrl} flexShrink={0} px={[3, 5]} variants={itemVariants} onClick={onClose}>
            <Flex h={10} align="center">
                <TraderAvatar size="sm" mr={1} trader={user} flexShrink={0} />
                <Box flexGrow={1} overflow="hidden">
                    <Text fontWeight="semibold" noOfLines={1} wordBreak="break-all">
                        {getUserName(user)}
                    </Text>
                    <Text fontSize="sm" noOfLines={1} wordBreak="break-all">
                        {user.email}
                    </Text>
                </Box>
                <IconCogWheel flexShrink={0} boxSize={3} ml={1} />
            </Flex>
        </MotionLink>
    );
};

interface IProps extends MotionBoxProps {
    nav: INav;
    user: IUser | null;
    onClose: () => void;
}

export const MenuMobile = (props: IProps) => {
    const { nav, user, onClose, ...otherProps } = props;
    const { t } = useTranslation("common");
    const { buildLoginModalLink, buildRegisterModalLink } = useLinkBuilder();
    const { trackMainNavClick } = useNavigationTracking();
    const path = useRouter().asPath;
    const accordionItemIndexRef = useRef<number | undefined>(undefined);

    const onAccordionItemClick = (url: string, index: number) => {
        if (accordionItemIndexRef.current !== index) {
            trackMainNavClick(url);
        } else if (accordionItemIndexRef.current === index) {
            accordionItemIndexRef.current = undefined;
        }
    };

    const onNavLinkClick = (linkUrl: string) => {
        trackMainNavClick(linkUrl);
        onClose();
    };

    return (
        <MotionBox
            as="nav"
            id={ID_NAV_MOBILE}
            pos="fixed"
            bottom="0"
            left="0"
            right="0"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            overflow="hidden"
            bg="white"
            pt={[3, 5]}
            zIndex="overlay"
            variants={menuOverlayVariants}
            initial="closed"
            animate="open"
            exit="closed"
            {...otherProps}
        >
            {user && nav.userSetting && <UserArea user={user} userSettingUrl={nav.userSetting.url} onClose={onClose} />}
            <Accordion flexGrow={1} allowToggle overflow="auto">
                {nav.main.map(({ link, children }, index) =>
                    children.length > 0 ? (
                        <AccordionItem
                            key={link.url}
                            mx={[3, 5]}
                            borderBottomStyle={index + 1 == nav.main.length ? "none" : "solid"}
                            borderColor="gray.100"
                            onClick={() => onAccordionItemClick(link.url, index)}
                        >
                            {({ isExpanded }) => {
                                if (isExpanded) {
                                    accordionItemIndexRef.current = index;
                                }

                                return (
                                    <>
                                        <AccordionButton h={[8, 10]} py={0} alignItems="center">
                                            <Box flex="1" textAlign="left">
                                                <Span fontSize={["md", "xl"]} noOfLines={1} wordBreak="break-all">
                                                    {link.name}
                                                </Span>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={[3, 2]}>
                                            <ChildNavItems items={children} currentPath={path} onClick={onClose} />
                                        </AccordionPanel>
                                    </>
                                );
                            }}
                        </AccordionItem>
                    ) : (
                        <NavLink
                            key={link.url}
                            href={link.url}
                            target={link.target}
                            isActive={link.url === path}
                            onClick={() => {
                                onNavLinkClick(link.url);
                            }}
                            onAuxClick={triggerCallbackOnMiddleClickEvent(() => onNavLinkClick(link.url))}
                            px={0}
                            h={[8, 10]}
                            mx={[3, 5]}
                            borderBottomColor="gray.100"
                            borderBottomStyle={index + 1 == nav.main.length ? "none" : "solid"}
                            borderBottomWidth="1px"
                        >
                            <Span fontSize={["md", "xl"]} noOfLines={1} wordBreak="break-all">
                                {link.name}
                            </Span>
                        </NavLink>
                    )
                )}
            </Accordion>
            <Box flexShrink={0} mt={0.5} px={[3, 5]}>
                <SimpleGrid columns={user ? 1 : 2} spacing={2} py={[3, 5]} borderTop="1px" borderColor="gray.100">
                    {user ? (
                        <Button as={WfLinkUnstyled} href={LOGOUT_URL} variant="outline">
                            {t("logout")}
                        </Button>
                    ) : (
                        <>
                            <Button className="gtm-nav-menu__login" as={HashLinkUnstyled} href={buildLoginModalLink()} variant="outline">
                                {t("login-button")}
                            </Button>
                            <Button as={HashLinkUnstyled} href={buildRegisterModalLink()} colorScheme="green">
                                {t("register")}
                            </Button>
                        </>
                    )}
                </SimpleGrid>
            </Box>
        </MotionBox>
    );
};
