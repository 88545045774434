import { createAddGenericActivityUrl } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useRouterLocale } from "src/utils/router/use-router-locale";

export enum EnumActivityType {
    InvestButtonPressed = 300,
    InvestViaBrokerlinkPressed = 301,
    CreateSavingsPlanViaBrokerLinkClicked = 302,
    VisitWikifolioDetailPage = 400,
    ShowBenchmarksClicked = 401,
    VisitWikifolioSearchPage = 500,
    RegisterShowInvestorModalRequestPhoneSupport = 1003,
    ContentPageVisit = 1100,
    MagazineArticleStarred = 1500,
    NewsArticleLiked = 1510,
    VwoExperimentSeen = 1600,
    VwoExperimentClicked = 1610,
    FunnelQuestionnaireAnswer = 1900,
    ActionOptionSelected = 1901,
    LikedTraderComment = 2000,
    CommentLiked = 2020,
    VisitedSmartFeed = 2100,
    VisitedWatchlist = 2101,
    VisitedPortfolio = 2102,
    WatchlistCustomFiguresSelected = 2120,
    NavigationClicked = 2200,
    NewsletterSubscriptionLinkClicked = 2300,
    StickyMessageBannerSeen = 2400,
    StickyMessageBannerClicked = 2401,
    AdClicked = 2500,
    TopTraderReactionsUnderlyingSeen = 2601,
    ReactionsRevealed = 2602,
    HelpscoutMessageClicked = 2700,
}

interface IRequest {
    activityTypeId: EnumActivityType;
    parameter?: string | null;
}

export const useGenericActivityMutation = () => {
    const { language, country } = useRouterLocale();
    const { mutatePromise } = useMutation<void, void, IRequest>(createAddGenericActivityUrl(language, country));

    return mutatePromise;
};
