import { Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ErrorCommon } from "src/components/pages/error/common";

const ERROR_404_TEXT_TEST_ID = "error-404-text";

export const Error404 = () => {
    const { t } = useTranslation("common");

    return (
        <ErrorCommon subHeadline={t("sub-headline-404")}>
            <Text mt={2} fontSize={["lg", "xl"]} textAlign={["center", "center", "unset"]} data-test-id={ERROR_404_TEXT_TEST_ID}>
                {t("400-error-heading")}
                <br />
                {t("400-error-message")}
            </Text>
        </ErrorCommon>
    );
};
