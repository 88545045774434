import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconWikifolioShortLogo: TIconComponent = props => (
    <Icon w="40px" h="30px" viewBox="0 0 40 30" {...props}>
        <g id="Logo">
            <path
                id="Vector"
                d="M26.7268 0L22.11 21.7133L18.5032 8.07935H12.1551L8.54824 21.7133L6.96123 14.4274H0L4.5807 29.5041H12.2633L15.3291 17.385L18.3949 29.5041H26.4382L33.6519 0H26.7268Z"
                fill="#95C11F"
            />
            <g id="Group">
                <path
                    id="Vector_2"
                    d="M33.7603 26.8709C33.7603 25.1036 35.0948 23.8412 36.8621 23.8412C38.6295 23.8412 40.0001 25.1036 40.0001 26.8709C40.0001 28.6022 38.6295 29.8646 36.8621 29.8646C35.0948 29.8646 33.7603 28.6022 33.7603 26.8709Z"
                    fill="#333333"
                />
            </g>
        </g>
    </Icon>
);
